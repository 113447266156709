
import First from '../../assets/fedex.png';
import Second from '../../assets/ups.png';
import Third from '../../assets/dhl.png';
import Fourth from '../../assets/usps.png';
import Fifth from '../../assets/shredding.png';
import Sixth from '../../assets/mailbox.png';

const exportedImages = [
    {
        title: "FedEx",
        description: "FedEx Express, Ground, and International - as your local FedEx Authorized ShipCenter and official drop-off location, Postal Center & More is your one-stop-shop for all FedEx shipping needs.",
        urls: First,
    },
    {
        title: "UPS",
        description: "Postal Center & More is your local UPS Authorized Shipping Outlet and drop-off location. UPS Next Day Air, 2nd Day Air, International, and Ground - whatever your shipping needs are, UPS can deliver!",
        urls: Second,
    },
    {
        title: "DHL",
        description: "The fastest and most reliable international shipping service in the world. The most difficult destinations that other carriers struggle to deliver to, DHL can.",
        urls: Third,
    },
    {
        title: "USPS",
        description: "Postage Stamp books and rolls, First Class Mail, Priority Mail, Priority Express Mail, International Mail, Certified Mail - if you need US Mail or package shipping service, we provide it.",
        urls: Fourth,
    },
    {
        title: "Shredding",
        description: "Identity theft is the fastest-growing financial crime in the world. Stop the bad guys from nabbing your sensitive personal and financial information by shredding it. Fast, effective, and secure document shredding.",
        urls: Fifth,
    },
    {
        title: "Mailbox Rental Service",
        description: "Safe, secure, and reliable private mailbox rentals with a street address (not a P.O. Box number), mail and package receiving, mail forwarding, and more, Great for business and privacy.",
        urls: Sixth,
    }
];

export default exportedImages