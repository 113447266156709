import React from "react";
import '../styles/pack.css'
import TabsPack from "../components/tabs/pack/TabsPack";

const Pack = () => {
    return(
        <div className="pack-container">
            <TabsPack />
        </div>
    )
}

export default Pack;