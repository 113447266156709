import React from "react";
import '../styles/service.css'
import Services from '../components/tabs/service/Services'

const Service = () => {
    return(
        <div className="business-container">
            <Services />
        </div>
    )
}

export default Service;