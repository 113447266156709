import React from "react";
import '../styles/contact.css'

const date = new Date();

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const currentDayOfWeek = daysOfWeek[date.getDay()];

const Contact = () => {
    return(
        <div className="contact-container">
            <p>{  }</p>
            <h1>323-654-4090</h1>
            <h1>wehomailboxes@gmail.com</h1>
            <h1>8149 Santa Monica Blvd, Los Angeles CA 90064</h1>
            <div className='shop-hours'>
                <div className='shop-hours-week'>
                    <h2 className={currentDayOfWeek === 'Monday' ? 'active-day' : ''}>Mon:</h2>
                    <h2 className={currentDayOfWeek === 'Tuesday' ? 'active-day' : ''}>Tue:</h2>
                    <h2 className={currentDayOfWeek === 'Wednesday' ? 'active-day' : ''}>Wed:</h2>
                    <h2 className={currentDayOfWeek === 'Thursday' ? 'active-day' : ''}>Thu:</h2>
                    <h2 className={currentDayOfWeek === 'Friday' ? 'active-day' : ''}>Fri:</h2>
                    <h2 className={currentDayOfWeek === 'Saturday' ? 'active-day' : ''}>Sat:</h2>
                    <h2 className={currentDayOfWeek === 'Sunday' ? 'active-day' : ''}>Sun:</h2>
                </div>
                <div className='shop-hours-hours'>
                    <h2 className={currentDayOfWeek === 'Monday' ? 'active-day' : ''}>9AM - 6PM</h2>
                    <h2 className={currentDayOfWeek === 'Tuesday' ? 'active-day' : ''}>9AM - 6PM</h2>
                    <h2 className={currentDayOfWeek === 'Wednesday' ? 'active-day' : ''}>9AM - 6PM</h2>
                    <h2 className={currentDayOfWeek === 'Thursday' ? 'active-day' : ''}>9AM - 6PM</h2>
                    <h2 className={currentDayOfWeek === 'Friday' ? 'active-day' : ''}>9AM - 6PM</h2>
                    <h2 className={currentDayOfWeek === 'Saturday' ? 'active-day' : ''}>9AM - 3PM</h2>
                    <h2 className={currentDayOfWeek === 'Sunday' ? 'active-day' : ''}>CLOSED</h2>
                </div>
            </div>
            <a
                href="https://www.google.com/maps/dir/Current+Location/8149+Santa+Monica+Blvd+Los+Angeles+CA+90046"
                target='_blank'
                className={'driving-instructions'}
                rel="noreferrer"
            >Driving Instructions</a>
            <iframe
                src="http://maps.google.com/maps?q=8149+Santa+Monica+blvd+los+angeles+ca+90046&output=embed"
                className={'map2d'}
                title="2d"
            />
            <iframe
                src="https://www.google.com/maps/embed?pb=!3m2!1sen!2sus!4v1713900891551!5m2!1sen!2sus!6m8!1m7!1sAzP_td-JrwayaXkSAr-0Jw!2m2!1d34.09089497687609!2d-118.3665155517794!3f346.6203157794703!4f12.533594802210985!5f0.7820865974627469"
                className={'map3d'}
                allowfullscreen="no"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                rel="noreferrer"
                title="3d"
            />
        </div>
    )
}

export default Contact;