import React from "react";
import '../styles/trackerror.css';

const handleClose = () => {
    window.open("about:blank", "_self");
    window.close();
}

const TrackError = () => {
    return(
        <div className="track-error-container">
            <h1 className="track-error-content">Unsupported Carrier or Invalid Tracking Number</h1>
            <button
                className="track-error-close"
                onClick={handleClose}
            >close</button>
        </div>
    )
};

export default TrackError;