import React, { useState } from "react";
import './tabsPack.css';
import fedexship from '../../../assets/fedexship.jpg';
import upsship from '../../../assets/upsship.jpg';
import uspsship from '../../../assets/uspsship.jpg';
import artworkship from '../../../assets/artworkship.jpg';
import electronicship from '../../../assets/electronicship.jpg';
import intship from '../../../assets/intship.jpg';
import postageship from '../../../assets/postageship.jpg';
import packsupply from '../../../assets/packsupply.jpg';
import propack from '../../../assets/propack.jpg';


const TabsPack = () => {
    const [activeTab, setTabActive] = useState('tab-1');

    const handleClick = (event) => {
        setTabActive(event.target.id);
      };
      
      return(
        <div>
            <div className="container">
                <div className="tabs">
                    <button
                        className={`tab-button ${activeTab === 'tab-1' ? 'active' : ''}`}
                        id="tab-1"
                        onClick={handleClick}
                    >
                        Carriers
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'tab-2' ? 'active' : ''}`}
                        id="tab-2"
                        onClick={handleClick}
                    >
                        Fragile
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'tab-3' ? 'active' : ''}`}
                        id="tab-3"
                        onClick={handleClick}
                    >
                        Int.
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'tab-4' ? 'active' : ''}`}
                        id="tab-4"
                        onClick={handleClick}
                    >
                        Supply
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'tab-5' ? 'active' : ''}`}
                        id="tab-5"
                        onClick={handleClick}
                    >
                        Pack
                    </button>
                </div>
                <div className="tabs-content">
                    <div className={`tab-page ${activeTab === 'tab-1' ? 'active' : ''}`}>
                        <div className="tab-content-container">
                            <img src={fedexship} className="tab-img" alt="fedex-tab-img"/>
                            <h1>FedEx Shipping in West Hollywood, CA</h1>
                            <h2>Your One Stop Shop For FedEx Express®, FedEx Ground®, and International Shipping Solutions</h2>
                            <p>
                                For fast, reliable service at competitive rates, FedEx delivers! Postal Center & More is an
                                officially recognized FedEx Authorized ShipCenter and as such provides convenient access to FedEx
                                Ground®, FedEx Express®, and FedEx International products and service. As one of the world’s
                                most-recognized brands, FedEx delivers your shipments to almost any address in the US and to millions
                                of addresses in over 200 countries worldwide.
                            </p>
                            <p>
                                Postal Center & More schedules daily FedEx pick-ups so you can be certain your shipments
                                receive the fast and efficient service you’ve come to expect from the FedEx brand.
                            </p>
                            <h2>Postal Center & More Offers The Following FedEx Services: </h2>
                            <ul className="tabs-ul">
                                <li> - FedEx Priority Overnight®</li>
                                <li> - FedEx Express Saver®</li>
                                <li> - FedEx Standard Overnight®</li>
                                <li> - FedEx 2 Day®</li>
                                <li> - FedEx Ground®</li>
                                <li> - FedEx Ground-Home Delivery®</li>
                                <li> - FedEx Priority International®</li>
                            </ul>
                            <h2>When It Comes To Shipping via FedEx In West Hollywood, CA, Postal Center & More Is Your
                                Go-To Source for shipping...
                            </h2>
                            <ul className="tabs-ul">
                                <li> - PC and laptop computers</li>
                                <li> - Documents</li>
                                <li> - Auto parts</li>
                                <li> - Medical devices</li>
                                <li> - Fragile & delicate items</li>
                                <li> - Video consoles & games</li>
                                <li> - Toys</li>
                                <li> - Electronics</li>
                                <li> - Office equipment & supplies</li>
                                <li> - Artwork</li>
                                <li> - Antiques</li>
                                <li> - Jewelry</li>
                                <li> - Clothing</li>
                                <li> - Household goods</li>
                                <li> - Samples</li>
                                <li> - Gifts</li>
                                <li> - Graduation presents</li>
                                <li> - Birthday items</li>
                            </ul>
                            <p>
                                Don’t forget, here at Postal Center & More we are packaging and shipping experts—bring us
                                your delicate, valuable, time-sensitive and sentimental items. We have shipping boxes for virtually
                                anything, and we’ll pack it with TLC to make sure it arrives in perfect condition.
                            </p>
                            <h3>Have A Question About FedEx Shipping?</h3>
                            <p>
                                Call 323-654-4090 to ask specific questions about FedEx shipments or to get a free quote.
                                To track a FedEx shipment, visit our Tracking Page.
                            </p>
                            <img src={upsship} className="tab-img" alt="ups-tab-img"/>
                            <h1>UPS Shipping in West Hollywood, CA</h1>
                            <h2>
                                Count On Postal Center & More To Handle All Of Your UPS Shipping—Ground, Express or
                                International
                            </h2>
                            <p>
                                United Parcel Service® (UPS) are the guys in the big brown trucks. They’ve been delivering to
                                businesses and consumers since 1909 and have a reputation for fast, reliable service at competitive
                                rates.
                            </p>
                            <h2>
                                Postal Center & More offers UPS products and services including
                            </h2>
                            <ul className="tabs-ul">
                                <li> - UPS Next Day Air®</li>
                                <li> - UPS 2-Day Air®</li>
                                <li> - UPS 3-Day Select®</li>
                                <li> - UPS Ground®</li>
                                <li> - UPS Canada®</li>
                                <li> - UPS Worldwide Express®</li>
                                <li> - UPS Worldwide Saver®</li>
                                <li> - UPS Puerto Rico®</li>
                            </ul>
                            <p>
                                Postal Center & More is a UPS Authorized Shipping Outlet (ASO) so we are experts on all UPS
                                options and services. We are also packaging experts and have dozens of box sizes, bubble wrap,
                                packing peanuts, tape, etc., so we can make sure your items are packed with TLC and arrive in
                                perfect condition.
                            </p>
                            <p>
                                UPS picks up from Postal Center & More daily, so you can be certain your shipments receive
                                the fast and efficient service you’ve come to expect from the UPS brand.
                            </p>
                            <h2>Have A Question About UPS Shipping?</h2>
                            <p>
                                Call 323-654-4090 to ask specific questions about your UPS shipment or to get a free quote. To track
                                a UPS shipment, visit our Tracking Page.
                            </p>
                            <img src={uspsship} className="tab-img" alt="usps-tab-img"/>
                            <h1>United States Postal Service (USPS) Shipping and Mailing in West Hollywood, CA</h1>
                            <h2>
                                Mailing letters, Priority Mail®, Priority Mail Express®, International Mail, postage stamps;
                                you name it, we provide it at Postal Center & More
                            </h2>
                            <p>
                                Avoid the long lines — avoid the Post Office and come to Postal Center & More where we
                                guarantee fast, efficient service with a smile—all for the right price.
                            </p>
                            <p>
                                Mail carriers for the United States Post Office go by or deliver to every address in the United
                                States every day, Monday through Saturday (and sometimes on Sunday). This fact makes USPS different
                                from all other carriers and is one of the reasons Postal Center & More provides easy and
                                convenient access to all-things postal.
                            </p>
                            <p>
                                Whether you need a single stamp, or want to get a care package to your sister in the Marine Corps,
                                we’re here to help.
                            </p>
                            <p>
                                Postal Center & More is an official USPS Approved Shipper which qualifies us to provide
                                several convenient postal products and services.
                            </p>
                            <h2>Domestic and International Mail & Shipping Services</h2>
                            <ul className="tabs-ul">
                                <li> - Stamps and First Class Mail ®</li>
                                <li> - Priority Mail®</li>
                                <li> - Standard Post™</li>
                                <li> - Media Mail™</li>
                                <li> - Certified Mail®</li>
                                <li> - Signature Confirmation™</li>
                                <li> - USPS Tracking</li>
                                <li> - Priority Mail Express International®</li>
                                <li> - Priority Mail Express® (Delivery Guaranteed)</li>
                                <li> - Priority Mail International®</li>
                            </ul>
                            <p>
                                Do you need your delicate, valuable, time-sensitive and sentimental items packed with TLC? That’s
                                what we do. We have shipping boxes for virtually anything, and our staff are trained as professional
                                packers, so we’ll make sure your stuff arrives in perfect condition.
                            </p>
                            <h3>Have A Question About USPS Post Office Mailing and Shipping?</h3>
                            <p>Call 323-654-4090 to ask specific questions about a USPS shipment.</p>
                        </div>
                    </div>
                    <div className={`tab-page ${activeTab === 'tab-2' ? 'active' : ''}`}>
                        <div className="tab-content-container">
                            <img src={artworkship} className="tab-img" alt="artwork-tab-img"/>
                            <h1>Artwork Shipping in West Hollywood, CA</h1>
                            <h2>Trust Your Next Art Shipment To The Experts</h2>
                            <p>
                                The Mona Lisa, your 5-year-old’s ceramic giraffe, a miniature statue of David, an Andy Warhol
                                painting---a hand-blown bowl—it’s all artwork and requires special handling and packaging for
                                shipping.
                            </p>
                            <p>
                                Did you know that anything shipped via UPS, FedEx, DHL or the US Postal Service must be packed well
                                enough to sustain a 3-foot drop without breaking?
                            </p>
                            <p>
                                The staff at Postal Center & More is expertly trained in all aspects of packaging, and we
                                specialize in packing and shipping artwork. We stock special boxes made specifically for framed art
                                and dozens of other box sizes, bubble wrap, packing peanuts, tape, and other tools and materials
                                that allow us to pack and ship your artwork to withstand any abuse the carriers might ‘throw’ at it.
                                And if we pack it for you, we guarantee it for insurability
                            </p>
                            <p>
                                For packing and shipping artwork in West Hollywood, CA, visit the experts at Beverly Hills U.S.
                                Mailboxes.
                            </p>
                            <h3>Have A Question About Artwork Shipping?</h3>
                            <p>For more information on our artwork shipping services, call us at 323-654-4090</p>
                        </div>
                        <img src={electronicship} className="tab-img" alt="electronics-tab-img"/>
                            <h1>Electronics & Computer Shipping in West Hollywood, CA</h1>
                            <h2>We Specialize In Shipping Delicate & Fragile Electronics</h2>
                            <p>
                                iPhones, laptops, Blue-ray players, tablets, audio systems, cable boxes, Geiger counters, medical
                                instruments, satellite dish controllers, TVs...if it has an “on” switch, it’s probably electronic
                                and needs extra-special handling when it comes to shipping.
                            </p>
                            <p>
                                Did you know that anything shipped via UPS, FedEx, DHL or the US Postal Service must be packed well
                                enough to sustain a 3-foot drop without breaking?
                            </p>
                            <p>
                                The staff at Postal Center & More is expertly trained in all aspects of packaging, and we
                                specialize in packing and shipping electronic items. We have all box sizes, bubble wrap, packing
                                peanuts, tape, and other tools and materials that allow us to pack and ship your electronics to
                                withstand any abuse the carriers might ‘throw’ at them. And if we pack it for you, we guarantee it
                                for insurability.
                            </p>
                            <p>
                                For packing and shipping electronics in West Hollywood, CA, visit the experts at Beverly Hills U.S.
                                Mailboxes.
                            </p>
                            <h3>Have A Question About Electronics Shipping?</h3>
                            <p>
                                For more information on our electronics shipping services, call us at 323-654-4090.
                            </p>
                    </div>
                    <div className={`tab-page ${activeTab === 'tab-3' ? 'active' : ''}`}>
                        <div className="tab-content-container">
                            <img src={intship} className="tab-img" alt="international-shipping-tab-img"/>
                            <h1>International Shipping in West Hollywood, CA</h1>
                            <h2>Fast & Reliable Package Delivery Around The World</h2>
                            <p>
                                Shipping an iPad to your Aunt in Des Moines is pretty simple. Sending an iPad to someone in Australia
                                is a different story.
                            </p>
                            <p>
                                International shipping is far more complicated than shipping domestically because every country has
                                different laws and rules regarding commodities entering their country. Some items can be shipped
                                without issue to Spain, but the same item may be prohibited for shipping to Mexico.
                            </p>
                            <p>
                                You also need to know the rules for import taxes, international duties, brokerage fees and other
                                additional costs that may be assessed on your shipment. And don’t forget the specialized
                                international document requirements—if you don’t include the proper paperwork, your shipment may
                                never arrive.
                            </p>
                            <p>
                                Relax. The International Shipping Experts at Postal Center & More know exactly how to pack,
                                document and ship your items to arrive safely and on time to [almost] anywhere in the world.
                            </p>
                            <h2>As International Shipping Specialists, we provide the following:</h2>
                            <ul className="tabs-ul">
                                <li> - Shipping to over 200 countries on 7 continents</li>
                                <li> - Multi-carrier shipping options</li>
                                <li> - International packing service</li>
                                <li> - International documentation – Commercial Invoice, Pro Forma invoice, E. E. I. forms</li>
                                <li> - Automated Airway bill</li>
                            </ul>
                            <p>
                                When it comes to international shipping in West Hollywood, CA, come to Postal Center & More
                                and simplify your life.
                            </p>
                            <h3>Have A Question About International Shipping?</h3>
                            <p>For more information on international shipping, call 323-654-4090.</p>
                        </div>
                    </div>
                    <div className={`tab-page ${activeTab === 'tab-4' ? 'active' : ''}`}>
                        <div className="tab-content-container">
                            <img src={packsupply} className="tab-img" alt="packing-supplies-tab-img"/>
                            <h1>Packaging & Moving Supplies in West Hollywood, CA</h1>
                            <h2>
                                Don’t Dumpster-Dive For Your Packaging & Moving Supplies, Postal Center & More Has
                                Everything You Need!
                            </h2>
                            <p>
                                Do you know what “Dimensional Weight” is? It’s a rule the shipping companies use to charge you for
                                the size of your package—and not just the weight. If you don’t use the correct box size, you may be
                                paying (a lot) more than you need to. Postal Center & More has a box to fit almost anything
                                — we’ll make sure you get the right one!
                            </p>
                            <h2>All The Packing & Moving Supplies You Need...</h2>
                            <ul className="tabs-ul">
                                <li> - Boxes — Over 50 different sizes — single wall and double wall — all at great prices!</li>
                                <li> - 
                                    Specialty Boxes — Artwork, laptops/computers, golf clubs, furniture,extra-large, extra-long
                                </li>
                                <li> - Packing Peanuts</li>
                                <li> - Bubble-Wrap — Buy it by the foot or by the roll</li>
                                <li> - Tape — Heavy-duty poly-tape, made for packing & shipping</li>
                                <li> -  Tape Guns — Simplify your life--cut your packing time in half.</li>
                                <li> - Mailing Tubes — For artwork, posters, blueprints, swords, etc.</li>
                                <li> - Padded Envelopes — Several sizes</li>
                                <li> - CD, DVD, and Photo Mailers</li>
                                <li> - Custom Box Making — We can custom-make a box to fit (almost) anything</li>
                                <li> - Need a bunch of boxes or packing materials? — We can custom-order large quantities</li>
                            </ul>
                            <p>Are you moving and need lots of stuff? We offer bundle pricing on large orders.</p>
                            <p>
                                Don’t like to pack?  Bring it to us, and we’ll pack it for you! The packing experts at Beverly Hills
                                U.S. Mailboxes are trained professionals. If we pack your items, they’re guaranteed for insurability.
                            </p>
                            <h3>Have A Question About Packing or Moving Supplies?</h3>
                            <p>Call 323-654-4090 if you have any questions about our packaging and moving supplies selection.</p>
                            <img src={postageship} className="tab-img" alt="postage-stamps-tab-img"/>
                            <h1>Postage Stamps in West Hollywood, CA</h1>
                            <h2>Perfect Last Minute Gift For All Occasions</h2>
                            <p>
                                Email may be pervasive, but everyone still needs regular postage stamps too – we have 'em here!
                            </p>
                            <p>
                                If you’ve visited your local Post Office lately to buy a book of stamps, you probably spent about
                                20 minutes in line, only to be treated rudely by a surly Post Office employee who is NOT happy to
                                see you.
                            </p>
                            <p>
                                Save yourself about 19 minutes by coming to Postal Center & More for your postage stamp
                                needs in West Hollywood, CA. Not only will we save you time, we’ll also be very happy to see you
                                and help make your life easier
                            </p>
                            <p>
                                If you need a special commemorative stamp or a large quantity, give us a call or send a message –
                                we can special order the stamps you need.
                            </p>
                            <h3>Have A Question About Postage Stamps?</h3>
                            <p>Questions about available stamps? Call us at 323-654-4090.</p>
                        </div>
                    </div>
                    <div className={`tab-page ${activeTab === 'tab-5' ? 'active' : ''}`}>
                        <div className="tab-content-container">
                            <img src={propack} className="tab-img" alt="professional-packing-tab-img"/>
                            <h1>Professional Packing in West Hollywood, CA</h1>
                            <h2>Big or Small, We Can Pack It All At Postal Center & More</h2>
                            <p>
                                Are you tired of trying to figure how to pack that special item so it arrives in one piece? You have
                                to find the right box size, hunt down the bubble-wrap, packing peanuts, tape, etc. It’s time
                                consuming, expensive, and a major inconvenience (and you may not pack it properly).
                            </p>
                            <p>
                                Proper packing is both a science and an art. Let the packing experts at Postal Center & More
                                professionally pack and ship your fragile, valuable, large, and awkward items. We are certified
                                retail packing specialists who treat your items as if we were packing our own stuff!
                            </p>
                            <p>
                                We stock over 50 sizes and keep plenty of styrofoam peanuts, bubble-wrap, tape, specialty boxes
                                (artwork, laptops, double-wall, etc.) and other packing materials on hand. We can even custom-make
                                a box for those elk antlers or car bumpers.
                            </p>
                            <p>
                                And our “Packed to Perfection” guarantee gives you peace of mind to know that your items are packed
                                to meet or exceed all carrier requirements, so if any damage or loss occurs during transit,
                                Postal Center & More stands behind our packaging—we guarantee it for insurability.
                            </p>
                            <h3>Have A Question About Our Packing Service?</h3>
                            <p>
                                Do you have a special item or lots of stuff that needs packing and have questions?
                                Call us at 323-654-4090.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabsPack;