import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Service from './pages/Service';
import Pack from './pages/Pack';
import Track from './pages/Track';
import Contact from './pages/Contact';
import About from './pages/About';
import NoPage from './pages/NoPage';
import TrackError from './pages/TrackError';
import Navbar from './pages/Navbar';
import "../node_modules/font-awesome/css/font-awesome.min.css"

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
            <Route index element={<Home />} />
            <Route path="services" element={<Service />} />
            <Route path="packandship" element={<Pack />} />
            <Route path="track" element={<Track />} />
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<About />} />
            <Route path="error*" element={<TrackError />} />
            <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);