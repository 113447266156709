import React from "react";
import '../styles/about.css'

const About = () => {
    return(
        <div className="about-container">
            <p className='about-info'>
                Postal Center & More | WeHoMailboxes - is an independently owned and operated packing,
                shipping, printing, and business services center located in Los Angeles, CA. We are
                professionally trained experts and members of the West Hollywood community who are
                dedicated to providing Super-Star Customer care in a convenient, efficient, and
                friendly environment
            </p>
        </div>
    )
}

export default About;