import React, { useState } from "react";
import './services.css';
import dropoffs from '../../../assets/dropoffship.jpg'
import docs from '../../../assets/documents.png';
import notary from '../../../assets/notary.jpg';
import payment from '../../../assets/payment.png';

const Services = () => {
    const [activeTab, setTabActive] = useState('tab-1');

    const handleClick = (event) => {
        setTabActive(event.target.id);
      };
      
    return(
        <div>
            <div className="container">
                <div className="tabs">
                    <button
                        className={`tab-button ${activeTab === 'tab-1' ? 'active' : ''}`}
                        id="tab-1"
                        onClick={handleClick}
                    >
                        Dropoffs
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'tab-2' ? 'active' : ''}`}
                        id="tab-2"
                        onClick={handleClick}
                    >
                        Documents
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'tab-3' ? 'active' : ''}`}
                        id="tab-3"
                        onClick={handleClick}
                    >
                        Notary
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'tab-4' ? 'active' : ''}`}
                        id="tab-4"
                        onClick={handleClick}
                    >
                        Payment
                    </button>
                </div>
                <div className="tabs-content">
                    <div className={`tab-page ${activeTab === 'tab-1' ? 'active' : ''}`}>
                        <div className="tab-content-container">
                            <img src={dropoffs} className="tab-img" alt="domestic-and-international-dropoffs-tab-img"/>
                            <h1>Domestic & International Dropoffs</h1>
                            <h2>Dropoff cutoff times are 3PM weekdays and 1PM Saturdays</h2>
                            <p>
                                Packages must be ready to go. We accept dropoffs for Fedex Ground, Fedex Express, UPS, and DHL.
                               
                                We do not accept USPS dropoffs except from mailbox owners.
                                
                                We do not accept QR Codes, please go to official carrier stores for non-label dropoffs.

                                Packages will not be held overnight/weekends (mailbox owners exempt).
                                
                                International packages containing commercial invoices/proforma must be place inside
                                of a resealable pouch affixed to the exterior of the package. 3 copies are required and must be
                                signed if indicated.
                                
                                Fedex Express International packages require 2 copies of the shipping label, one of which or
                                both must be inside of a resealable pouch.
                                
                                We do not offer label printing or package taping services.
                                
                                You may purchase tape if you need it.
                                
                                Please come with the package sealed and ready to go.
                            </p>
                        </div>
                    </div>
                    <div className={`tab-page ${activeTab === 'tab-2' ? 'active' : ''}`}>
                        <div className="tab-content-container">
                            <img src={docs} className="tab-img" alt="documents-tab-img"/>
                            <h1>Print/Fax/Scan/Shred</h1>
                            <h2>Printing</h2>
                            <p>
                                We offer printing services in black & white for $1/page.
                                We do not print labels for dropoffs, please come with dropoffs ready to go.
                                Any documents to be printed must be emailed to wehomailboxes@gmail.com
                            </p>
                            <h2>Faxing</h2>
                            <p>
                                Faxing services are available for $2/page.
                                A cover letter attached to your fax is available for $2.
                                Please make sure to have your documents ready with staples removed.
                            </p>
                            <h2>Scanning</h2>
                            <p>
                                HD 300 DPI document scanning in color available for $2/page.
                                Format will be PDF and emailed to you or can be faxed.
                                Please make sure to have your documents ready with staples removed.
                            </p>
                            <h2>Shredding</h2>
                            <p>
                                Secure shredding for sensitive documents available for $1/LBS.
                                Your documents will be placed inside of a bin secured with a lock.
                                Nobody will be able to access your information.
                                A professional shredding company will be called to securely dispose of your documents.
                            </p>
                        </div>
                    </div>
                    <div className={`tab-page ${activeTab === 'tab-3' ? 'active' : ''}`}>
                        <div className="tab-content-container">
                            <img src={notary} className="tab-img" alt="notary-tab-img"/>
                            <h1>We currently do not offer Notary services</h1>
                            <h2>Closest Notary service available at:</h2>
                            <h2>DOKUMENT USA</h2>
                            <h2>1123 N FAIRFAX AVE</h2>
                        </div>
                    </div>
                    <div className={`tab-page ${activeTab === 'tab-4' ? 'active' : ''}`}>
                        <div className="tab-content-container">
                            <img src={payment} className="tab-img" alt="payment-tab-img"/>
                            <h1>Payment Methods Accepted:</h1>
                            <h2>Cash, Checks, and Credit & Debit Cards (VISA/Mastercard/AMEX/Discover etc)</h2>
                            <p>
                                We do not accept Apple Pay.
                                Physical card not required but P\please make sure to at least have your card number and
                                expiration date ready.
                                We do not accept Cashapp/Venmo/PayPal etc.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;