import '../styles/tracker.css';
import React, {useState} from 'react';
import { getTracking } from 'ts-tracking-number';

const Tracker = () => {
    const [trknum, setTrknum] = useState('');
    const [carrier, setCarrier] = useState('');

    const tracking = getTracking(trknum);
    const handleFocus = (event) => event.target.select();

    const check = () => {
        if(tracking !== undefined && tracking.courier.code.toLocaleLowerCase() === "fedex") setCarrier('https://www.fedex.com/fedextrack/?trknbr=');
        else if(
            (tracking !== undefined && tracking.courier.code.toLocaleLowerCase() === "usps") ||
            (tracking !== undefined && tracking.courier.code.toLocaleLowerCase() === "s10")
        ) setCarrier('https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=');
        else if(tracking !== undefined && tracking.courier.code.toLocaleLowerCase() === "ups") setCarrier('https://www.ups.com/track?track=yes&trackNums=');
        else if(tracking !== undefined && tracking.courier.code.toLocaleLowerCase() === "dhl") setCarrier('https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=');
        else setCarrier('error/')
    }
    
    // fedex 273646615833
    // ups 1Z7681A20395875610
    // usps LZ640731146US
    // dhl 1737119440
    return(
        <div className='trk-container'>
            <input  className={"trkInput"}
                    placeholder='Fedex | UPS | DHL | USPS'
                    onChange={event => setTrknum(event.target.value.trim())}
                    onFocus={handleFocus}
            />
            <a  className={"trkBtn"}
                onClick={check}
                href={ carrier + trknum }
                target='_blank'
                rel="noreferrer"
            >Track</a>
            <div className="track-sample">
            </div>
        </div>
    )
};

export default Tracker;