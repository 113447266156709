import React from "react";
import Tracker from '../components/Tracker';
import '../styles/track.css';

const Track = () => {
    return(
        <div className='track-container'>
            <Tracker />
        </div>
    )
}

export default Track;