import React from "react";
import '../styles/home.css';
import Slider from "../components/carousel/Slider";
import fedexLogo from '../assets/fedexlogo.png';
import upsLogo from '../assets/upslogo.jpg';
import dhlLogo from '../assets/dhllogo.png';
import uspsLogo from '../assets/uspslogo.png';

const date = new Date();

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const currentDayOfWeek = daysOfWeek[date.getDay()];

// const currentTime = date.toLocaleTimeString();

const weekDays = ["3PM", "3PM", "3PM", "3PM", "4PM"];
const weekSat = ["CLOSED", "2PM", "CLOSED", "CLOSED", "2PM"];
const weekSun = ["CLOSED", "CLOSED", "CLOSED", "CLOSED", "CLOSED"];

let weekStatus = [];

if(currentDayOfWeek === 'Saturday'){
    weekStatus = weekSat;
} else if (currentDayOfWeek === "Sunday") {
    weekStatus = weekSun;
} else {
    weekStatus = weekDays;
}

const Home = () => {
    return(
        <div className='home-container'>
            <div className='carriers'>
                <Slider />
                <div className='carrier-pickups-container'>
                    <h1>▶ {currentDayOfWeek} Pick-UP</h1>
                    <div className='carrier-pickups'>
                        <img src={fedexLogo} alt="carrier logo"/>
                        <div className='carrier-pickups-text'>
                            <h2>Fedex Ground</h2>
                            <h3>{weekStatus[0]}</h3>
                        </div>
                        <div className='carrier-pickups-text'>
                            <h2>Fedex Express</h2>
                            <h3>{weekStatus[1]}</h3>
                        </div>
                        <img src={upsLogo} alt="carrier logo"/>
                        <div className='carrier-pickups-text'>
                            <h2>UPS</h2>
                            <h3>{weekStatus[2]}</h3>
                        </div>
                        <img src={dhlLogo} alt="carrier logo"/>
                        <div className='carrier-pickups-text'>
                            <h2>DHL</h2>
                            <h3>{weekStatus[3]}</h3>
                        </div>
                        <img src={uspsLogo} alt="carrier logo"/>
                        <div className='carrier-pickups-text'>
                            <h2>USPS</h2>
                            <h3>{weekStatus[4]}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-text">
                <h1>Welcome to Postal Center & More | WeHo Mailboxes</h1>
                <p>
                    Postal Center & More located at 8149 Santa Monica Blvd, Los Angeles CA, 90069
                    is the go-to resource for packing, shipping, printing, and business service needs
                    of the residents and businesses of WeHo, CA. Our team of dedicated, professionally
                    trained experts understands the meaning of Super-Star Customer Care—we focus on saving
                    you time and money by ensuring you get the right products and services at the right
                    price—in a single, quick visit.
                </p>
                <p>
                    We can pack and ship [almost] anything to [almost] anywhere in the world, but
                    shipping is only one way we can help to make your life easier. In addition to our
                    pack and ship service, we are also WeHo's premier copy, print, and document services
                    center. Large or small, if it can be printed, Postal Center & More can handle it!
                </p>
                <p>
                    Postal Center & More is a one-stop-shop for dozens of business products and services
                    that will allow you to do what you're good at while we take care of the "other stuff"
                    you need to succeed.
                </p>
            </div>
        </div>
    )
}

export default Home;